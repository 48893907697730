/**
 * @prettier
 */

// React Packages
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Webcam from 'react-webcam';
import i18n from '../i18n';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';

// Actions
import { processID } from './actions/processDataActions';
import { setAutoLivenessError } from './actions/configActions';
import { clearError } from './actions/navigatorActions';
import { completeStage } from './actions/submissionStateActions';

// Services
import apiService from '../services/api/api';
import logService from '../services/shared/logService';
import DataDogService from '../services/shared/datadogService';
import {
    getCompanyName,
    isSubmissionComplete,
} from '../services/shared/helpers';

//  Components
import Header from './Header';
import Navigator from './Navigator';
import CustomButton from './Button';
import StageComplete from './Messages/StageComplete';

// Images
import livenessSvg from '../assets/img/liveness.svg';
import livenessAutoSvg from '../assets/img/liveness_auto_instructions.svg';
import loadingGif from '../assets/gifs/loading.gif';
import livenessInstructionGif from '../assets/gifs/passive_instruction.gif';

// Config
import { imageAlt } from '../config/accessabilityRules';
import { ACTION_LABELS } from '../config/dataDogActionLabels';
import InstructionModal from './Messages/InstructionModal';

const ACTION = ACTION_LABELS.captureSelfie;

class CaptureSelfie extends Component {
    constructor(props) {
        super(props);
        this.primaryFocusRef = React.createRef();
        this.state = {
            isAuto: false,
            livenessError: false,
            loading: false,
            inputValue: '',
            selfie: null,
            autoLivenessStarted: false,
            photoCaptured: false,
            captureInstructions: null,
            startVideoSteamCapture: false,
            screenshot: null,
            videoConstraints: {
                facingMode: 'user',
                width: window.innerHeight, // Note that width and height need to be inverted on mobile
                height: window.innerWidth,
            },
            navigation: {
                action: 'load',
                props: null,
            },
        };
    }

    componentDidMount() {
        // Sets focus to primary heading on first render
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            this.primaryFocusRef.current.focus();
        }

        if (this.props.bypassAcuant) {
            this.setState({
                navigation: {
                    action: 'next',
                    props: {
                        bypassAcuant: true,
                    },
                },
            });
        }

        // Sets document title
        const { t } = this.props;
        document.title = t('idpal_doc_title_liveness');
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.resultData !== this.props.resultData) {
            this.processSelfieAndRedirect();
        }

        // Sets focus to primary heading on first render
        if (this.primaryFocusRef && this.primaryFocusRef.current) {
            this.primaryFocusRef.current.focus();
        }

        // Sets document title
        const { t } = this.props;
        if (this.state.livenessError) {
            document.title = t('idpal_doc_title_liveness_error');
        } else {
            document.title = t('idpal_doc_title_liveness');
        }
    }

    // Updates the language translation strings for the camera overlay
    updateText(text) {
        const { t } = this.props;
        const acuantCameraOverLay = document.querySelector(
            '#acuant-face-capture-camera'
        );
        if (text === 'close') {
            const close = acuantCameraOverLay.shadowRoot.querySelector(
                '.controlPanel div div div'
            );
            close.innerHTML = t('idpal_close');
        } else if (text === 'retake') {
            const retake =
                acuantCameraOverLay.shadowRoot.querySelector('.retake');
            retake.innerHTML = t('idpal_retake');
        } else if (text === null) {
            const removeText = acuantCameraOverLay.shadowRoot.querySelector(
                '.controlPanel div div div'
            );
            removeText.innerHTML = '';
        } else {
            return;
        }
    }

    openFrontCamera() {
        const faceDetectionStates = {
            FACE_NOT_FOUND: 'idpal_move_face',
            TOO_MANY_FACES: 'idpal_too_many_faces',
            FACE_ANGLE_TOO_LARGE: 'idpal_move_face',
            PROBABILITY_TOO_SMALL: 'idpal_move_face',
            FACE_TOO_SMALL: 'idpal_move_closer',
            FACE_CLOSE_TO_BORDER: 'idpal_move_face',
        };

        var faceCaptureCallback = {
            onDetectorInitialized: () => {
                //This callback is triggered when the face detector is ready.
                //Until then, no actions are executed and the user sees only the camera stream.
                //You can opt to display an alert before the callback is triggered.
                // Updates the close language string in the camera overlay
                setTimeout(() => this.updateText('close'), 50);
            },
            onDetection: text => {
                //Triggered when the face does not pass the scan. The UI element
                //should be updated here to provide guidence to the user
                this.setState({
                    autoLivenessStarted: true,
                    captureInstructions: text,
                });
            },
            onOpened: () => {
                //Camera has opened
                // Updates the close language string in the camera overlay
                setTimeout(() => this.updateText('close'), 80);
            },
            onClosed: () => {
                //Camera has closed
                // Removes translation strings for overlay on close
                this.updateText(null);

                this.setState({
                    autoLivenessStarted: false,
                    photoCaptured: false,
                });
            },
            onError: error => {
                //Error occurred. Camera permission not granted will
                //manifest here with 1 as error code. Unexpected errors will have 2 as error code.
                this.props.setAutoLivenessError();
                this.setState({
                    autoLivenessStarted: false,
                    photoCaptured: false,
                    livenessError: true,
                });
                logService.error(error);
            },
            onPhotoTaken: () => {
                //The photo has been taken and it's showing a preview with a button to accept or retake the image.
                this.setState({
                    autoLivenessStarted: false,
                    photoCaptured: true,
                });
                // Updates the retake language string in the camera overlay
                setTimeout(() => this.updateText('retake'), 50);
            },
            onPhotoRetake: () => {
                //Triggered when retake button is tapped
                this.setState({
                    autoLivenessStarted: false,
                    photoCaptured: false,
                });
            },
            onCaptured: image => {
                this.onCaptured(image);
                this.setState({
                    autoLivenessStarted: false,
                    photoCaptured: false,
                });
            },
        };

        // autoLivenessSupported checks if auto liveness is supported and toggled on
        // Feature toggle is set in the config actions, this will be removed with feature toggles
        if (this.props.autoLivenessSupported && !this.state.livenessError) {
            try {
                AcuantPassiveLiveness.start(
                    faceCaptureCallback,
                    faceDetectionStates
                );
            } catch (error) {
                DataDogService.error(error);
            }
        } else {
            // Manual capture is started if autoLiveness is disabled, unsupported or an error occurs
            try {
                AcuantPassiveLiveness.startManualCapture(
                    faceCaptureCallback.onCaptured
                );
            } catch (error) {
                DataDogService.error(error);
            }
        }
    }

    captureScreenshotFromVideo() {
        const screenshot = this.webcam.getScreenshot();
        // this.setState({ screenshot });
        const image = screenshot.replace(
            /^data:image\/(png|jpg|jpeg);base64,/,
            ''
        );
        this.onCaptured(image);
    }

    onCaptured(image) {
        clearError(this);
        this.setState({ loading: true });

        // Send passive liveness selfie image to the server for processing
        apiService
            .getPassiveLivenessResult(image)
            .then(response => {
                this.props.dispatch({
                    payload: response,
                    type: '@@acuant/ADD_FACE_LIVENESS_DATA',
                });
                DataDogService.log('Successfully Captured Selfie.');
                this.props.completeStage('passive_liveness');
                this.setState({
                    navigation: {
                        action: 'next',
                    },
                });
            })
            .catch(error => {
                DataDogService.createError(
                    'Unable to send liveness for verification.'
                );
                this.setState({ loading: false });
                logService.error(error);
                this.setState({
                    navigation: {
                        action: 'error',
                        props: {
                            retryAction: () => this.onCaptured(image),
                            error: error,
                        },
                    },
                });
            });
    }

    showInstructionModal() {
        if (this.props.isPoaEnabled) {
            if (
                this.props.docCompleted &&
                this.props.isDocSubmitted &&
                this.props.poaCompleted &&
                this.props.isPoaDocSubmitted
            ) {
                return true;
            }
        }

        if (!this.props.isPoaEnabled) {
            if (this.props.docCompleted && this.props.isDocSubmitted) {
                return true;
            }
        }

        return false;
    }

    render() {
        const { t } = this.props;

        if (isSubmissionComplete()) {
            return (
                <Fragment>
                    <Header />
                    <StageComplete
                        message={t('idpal_your_submission_is_complete', {
                            company: getCompanyName(this.props.companyName),
                        })}
                        hideContinue={true}
                        hideButton={true}
                    />
                </Fragment>
            );
        }

        return (
            <Fragment>
                {this.showInstructionModal() && (
                    <InstructionModal
                        heading={t('idpal_document_upload_completed')}
                        message={t('idpal_document_upload_completed_message')}
                        image={livenessInstructionGif}
                        cta={t('idpal_continue')}
                        showCta={true}
                    />
                )}

                <Header />
                {!this.state.livenessError && (
                    <div id='acuant-face-capture-container'>
                        {this.state.autoLivenessStarted && (
                            <div className='auto_instructions'>
                                <div className='container'>
                                    <div className='background'></div>
                                    <div
                                        className={
                                            i18n.language === 'es'
                                                ? 'text-position sm-text'
                                                : 'text-position'
                                        }
                                    >
                                        {t(this.state.captureInstructions)}
                                    </div>
                                </div>
                            </div>
                        )}
                        {this.state.photoCaptured && (
                            <div className='submit-text'>
                                {t('idpal_submit')}
                            </div>
                        )}
                    </div>
                )}
                {this.state.loading && (
                    <div className='o-site-wrap'>
                        <div className='u-generic-text  u-text-center u-btm-buffer'>
                            <h1
                                ref={this.primaryFocusRef}
                                tabIndex={0}
                                className='loading-ellipse'
                            >
                                {t('idpal_analysing')}
                                <span className='dot1'>.</span>
                                <span className='dot2'>.</span>
                                <span className='dot3'>.</span>
                            </h1>
                        </div>

                        <div className='u-display-loading u-text-center'>
                            <img
                                alt={imageAlt.loading}
                                src={loadingGif}
                                className='capture'
                            />
                        </div>
                    </div>
                )}

                {this.state.startVideoSteamCapture && (
                    <div className='body capture_selfie'>
                        <Webcam
                            audio={false}
                            ref={node => (this.webcam = node)}
                            screenshotQuality={1}
                            mirrored={true}
                            imageSmoothing={false}
                            screenshotFormat='image/jpeg'
                            videoConstraints={this.state.videoConstraints}
                        />
                        <div className={'button_container'}>
                            <CustomButton
                                id={'capture'}
                                className='btn'
                                label='Capture photo'
                                handleClick={this.captureScreenshotFromVideo.bind(
                                    this
                                )}
                                actionDataLabel={ACTION.videoStreamButton}
                            />
                        </div>
                        {this.state.screenshot && (
                            <img alt='' src={this.state.screenshot} />
                        )}
                    </div>
                )}

                {!this.state.startVideoSteamCapture && !this.state.loading && (
                    <div className='o-site-wrap instructions'>
                        {this.state.livenessError ? (
                            <div>
                                <div className='u-generic-text u-text-center error-buffer'>
                                    {t('idpal_problem_accessing_camera')}
                                </div>

                                <p className='u-generic-text u-text-center u-md-btm-buffer'>
                                    {t(
                                        'idpal_passive_liveness_instruction_continued'
                                    )}
                                </p>

                                <div className='u-generic-text u-text-center'>
                                    <CustomButton
                                        id={'capture'}
                                        className='btn'
                                        label={t('idpal_manual_capture')}
                                        handleClick={this.openFrontCamera.bind(
                                            this
                                        )}
                                        actionDataLabel={
                                            ACTION.openFrontCameraButton
                                        }
                                    />
                                </div>
                            </div>
                        ) : (
                            <div>
                                <h1
                                    className='u-generic-text  u-text-center'
                                    ref={this.primaryFocusRef}
                                    tabIndex={0}
                                >
                                    {this.props.autoLivenessSupported
                                        ? t('idpal_time_for_liveness_test')
                                        : t(
                                              'idpal_passive_liveness_instruction'
                                          )}
                                </h1>

                                <div className='u-display-selfie u-text-center'>
                                    <div className='u-display-img-wrap'>
                                        <img
                                            alt={imageAlt.selfieInstructions}
                                            className='capture'
                                            src={
                                                this.props.autoLivenessSupported
                                                    ? livenessAutoSvg
                                                    : livenessSvg
                                            }
                                        />
                                    </div>
                                </div>

                                <p className='u-generic-text  u-text-center'>
                                    {this.props.autoLivenessSupported
                                        ? t('idpal_position_your_face_in_oval')
                                        : t(
                                              'idpal_passive_liveness_instruction_continued'
                                          )}
                                </p>

                                <div className='u-generic-text u-text-center'>
                                    <CustomButton
                                        id={'capture'}
                                        className='btn'
                                        label={t('idpal_open_camera')}
                                        handleClick={this.openFrontCamera.bind(
                                            this
                                        )}
                                        actionDataLabel={
                                            ACTION.openFrontCameraButton
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )}

                <Navigator
                    page={'passive_liveness'}
                    action={this.state.navigation.action}
                    propsToPass={this.state.navigation.props}
                />
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
        bypassAcuant: state.config.bypassAcuant,
        autoLivenessSupported: state.config.autoLivenessSupported,
        faceMatch: state.processedData.faceMatch,
        resultData: state.processedData.result,
        liveness: state.processedData.liveness,
        submissionState: state.submissionState,
        isDocSubmitted: state.config.isDocSubmitted,
        isPoaDocSubmitted: state.config.isPoaDocSubmitted,
        docCompleted:
            state.submissionState.submissionState.document_upload.completed,
        poaCompleted:
            state.submissionState.submissionState.poa_upload.completed,
        isPoaEnabled: state.submissionState.screens.poa_upload.enabled,
        companyName: state.config.profile.data.company_branding.name,
    };
}

function mapDispatchToProps(dispatch) {
    const actions = bindActionCreators(
        { processID, setAutoLivenessError, completeStage },
        dispatch
    );
    return { ...actions, dispatch };
}

export default withTranslation('translation')(
    connect(mapStateToProps, mapDispatchToProps)(CaptureSelfie)
);
