/**
 * @prettier
 */

import { isMobile, isTablet } from 'react-device-detect';
import {
    setIsAutoLivenessSupported,
    setIsDocSubmitted,
    setIsIdCaptureSupported,
    setIsPoaSubmitted,
} from './configActions';
import selectStorage from '../../services/shared/selectStorage';
import { areCookiesEnabled } from '../../services/shared/helpers';

export function initialiseState(data) {
    return dispatch => {
        dispatch(setSubmissionState(data));
        dispatch(setRetryLimit(data.state.document_upload.remaining));

        // Check if an ID has been submitted
        if (
            data.state.document_upload.completed ||
            data.state.document_upload.remaining <= 0
        ) {
            dispatch(setIsDocSubmitted(true));
        } else {
            dispatch(setIsDocSubmitted(false));
        }

        // Deal with cases when all attempts are used & doc upload is not set as complete
        // This happens with expired documents.
        if (data.state.document_upload.remaining <= 0) {
            dispatch(completeStage('document_upload'));
        }

        // Check if a poa doc has been submitted
        if (data.state.poa_upload.completed) {
            dispatch(setIsPoaSubmitted(true));
        } else {
            dispatch(setIsPoaSubmitted(false));
        }

        if (
            selectStorage(areCookiesEnabled()).getItem('handoff_session') !==
                null &&
            selectStorage(areCookiesEnabled()).getItem('target') ===
                'webapp_mobile' &&
            (isMobile || isTablet)
        ) {
            dispatch(isHandoff(true));
        } else {
            dispatch(isHandoff(false));
        }

        dispatch(
            setIsAutoLivenessSupported(
                data.screens.passive_liveness.auto_capture
            )
        );
        dispatch(
            setIsIdCaptureSupported(
                data.screens.document_upload.auto_capture,
                data.screens.document_upload.capture_method
            )
        );
        dispatch(
            setIsDocumentSelectionEnabled(
                data.screens.document_upload.id_document_selection
                    ? true
                    : false
            )
        );
        return dispatch({
            type: 'COMPLETE',
        });
    };
}

export function setSubmissionState(data) {
    return {
        type: 'SET_SUBMISSION_STATE',
        data: data,
    };
}

export function completeStage(stage) {
    return {
        type: 'SET_STAGE_COMPLETED',
        data: stage,
    };
}

export function submissionAttempt(side) {
    return {
        type: 'RECORD_SUBMISSION_ATTEMPT',
        data: side,
    };
}

export function isHandoff(bool) {
    return {
        type: 'IS_HANDOFF',
        data: bool,
    };
}

export function setRetryLimit(limit) {
    return {
        type: 'SET_RETRY_LIMIT',
        data: limit,
    };
}

export function submissionStarted() {
    return {
        type: 'SUBMISSION_STARTED',
    };
}

export function clearSubmissionState() {
    return {
        type: 'CLEAR_SUBMISSION_STATE',
    };
}

export function setIsDocumentSelectionEnabled(isDocSelectionEnabled) {
    return {
        type: 'IS_DOC_SELECTION_ENABLED',
        data: isDocSelectionEnabled,
    };
}
