/**
 * @prettier
 */

// React Packages
import { isMobile } from 'react-device-detect';

// Actions
import { initialiseEVerification } from './eVerificationActions';
import { initialiseState } from './submissionStateActions';

// Services
import {
    checkCameraAccess,
    isIOS,
    isSubmissionComplete,
} from '../../services/shared/helpers';
import ApiService from '../../services/api/api';
import logService from '../../services/shared/logService';
import DataDogService from '../../services/shared/datadogService';
import selectStorage from '../../services/shared/selectStorage';
import { areCookiesEnabled } from '../../services/shared/helpers';

export function setInstanceID(context) {
    return dispatch => {
        let ruuid = selectStorage(areCookiesEnabled()).getItem('ruuid');
        let uuid = selectStorage(areCookiesEnabled()).getItem('uuid');

        // First check if the submission is complete
        if (isSubmissionComplete()) {
            selectStorage(areCookiesEnabled()).removeItem('uuid');
            selectStorage(areCookiesEnabled()).removeItem('sessionid');
            uuid = null;
            ruuid = null;
        }

        dispatch(
            {
                type: 'SHOW_HEADER',
                data:
                    selectStorage(areCookiesEnabled()).getItem(
                        'disableheader'
                    ) === 'true'
                        ? false
                        : true,
            },
            dispatch({
                type: 'RESET_SPINNER',
            }),
            dispatch({
                type: 'RESET_E_VERIFICATION',
            })
        );

        if (ruuid !== null && ruuid.length) {
            selectStorage(areCookiesEnabled()).removeItem('uuid');
            dispatch({
                type: 'IS_RUUID',
                data: true,
            });
            dispatch({
                type: 'IS_INIT_WITH_RUUID',
                data: true,
            });
            dispatch({ type: 'PENDING' });

            // Get the Branding
            ApiService.getBrandingFromRuuid(
                selectStorage(areCookiesEnabled()).getItem('ruuid')
            )
                .then(response => {
                    const data = response.data;
                    if (data) {
                        dispatch({
                            type: 'PRE_LOAD_BRANDING',
                            data: data,
                        });
                    }
                    dispatch({ type: 'COMPLETE' });
                })
                .catch(err => {
                    DataDogService.createError(
                        'Could not retrieve branding from RUUID.'
                    );
                    dispatch({ type: 'BRANDING_ERROR' });
                    dispatch({ type: 'COMPLETE' });
                });
        }

        if (uuid !== null && uuid.length) {
            dispatch({ type: 'PENDING' });

            ApiService.getDocInstance()
                .then(data => {
                    dispatch({ type: 'COMPLETE' });

                    if (data && data.hasOwnProperty('success')) {
                        // Could not retrieve session, UUID invalid
                        if (data.success === false) {
                            DataDogService.createError(
                                'Error Initializing Application: Invalid UUID'
                            );
                            return dispatch({
                                type: 'SET_UUID_VALIDITY',
                                data: false,
                            });
                        }

                        // add session id cookie from the api once we make contact
                        if (data.hasOwnProperty('sessionid')) {
                            selectStorage(areCookiesEnabled()).setItem(
                                'sessionid',
                                data.sessionid
                            );
                            dispatch({ type: 'PENDING' });

                            ApiService.getAppProfile()
                                .then(response => {
                                    let data = response.data;
                                    dispatch({ type: 'COMPLETE' });

                                    // Store crop settings
                                    if (data.profile.crop_settings) {
                                        dispatch({
                                            type: 'SET_CROP_SETTINGS',
                                            data: data.profile.crop_settings,
                                            device: isMobile
                                                ? 'mobile'
                                                : 'desktop',
                                        });
                                    }

                                    if (data.limitReached === true) {
                                        // Limit has been reached, display message, but still set branding
                                        DataDogService.log(
                                            'User has reached UUID Limit.'
                                        );
                                        dispatch(
                                            { type: 'LIMIT_REACHED' },
                                            dispatch({
                                                type: 'SET_UUID_VALIDITY',
                                                data: true,
                                            })
                                        );
                                    } else {
                                        dispatch({
                                            type: 'SET_UUID_VALIDITY',
                                            data: true,
                                        });
                                    }

                                    // if e-verification is details are present in profile, initialise data
                                    if (
                                        data.profile['e_verification_details'][
                                            'enabled'
                                        ] === true
                                    ) {
                                        dispatch(
                                            initialiseEVerification(
                                                data.profile[
                                                    'e_verification_details'
                                                ]
                                            )
                                        );
                                    }

                                    if (
                                        response.code === 200 &&
                                        data &&
                                        data.profile.hasOwnProperty(
                                            'company_branding'
                                        )
                                    ) {
                                        // Log info
                                        logService.log(
                                            'setBranding',
                                            data.profile
                                        );

                                        dispatch({ type: 'PENDING' });

                                        ApiService.getSubmissionState()
                                            .then(state => {
                                                if (
                                                    state.data &&
                                                    state.data.details.hasOwnProperty(
                                                        'submission_id'
                                                    )
                                                ) {
                                                    // Set Submission ID
                                                    selectStorage(
                                                        areCookiesEnabled()
                                                    ).setItem(
                                                        'submissionid',
                                                        state.data.details
                                                            .submission_id
                                                    );
                                                    dispatch(
                                                        initialiseState(
                                                            state.data
                                                        )
                                                    );
                                                } else {
                                                    dispatch({
                                                        type: 'COMPLETE',
                                                    });
                                                    return dispatch({
                                                        type: 'LIMIT_REACHED',
                                                    });
                                                }
                                            })
                                            .catch(err => {
                                                DataDogService.createError(
                                                    'Could not get state.'
                                                );
                                                dispatch({ type: 'COMPLETE' });
                                                logService.error(err);
                                                context.setState({
                                                    navigation: {
                                                        action: 'error',
                                                        props: {
                                                            retryAction: () =>
                                                                context.props.setInstanceID(
                                                                    context
                                                                ),
                                                            error: err,
                                                        },
                                                    },
                                                });
                                            });
                                        return dispatch(
                                            {
                                                type: '@@acuant/SET_INSTANCE_ID',
                                                data: data.sessionid,
                                            },
                                            dispatch({
                                                type: '@@acuant/SET_BRANDING',
                                                data: data.profile,
                                            })
                                        );
                                    } else {
                                        DataDogService.createError(
                                            'Could not get profile.'
                                        );
                                        return dispatch({
                                            type: 'SET_UUID_VALIDITY',
                                            data: false,
                                        });
                                    }
                                })
                                .catch(err => {
                                    DataDogService.createError(
                                        'Could not get profile'
                                    );
                                    dispatch({ type: 'COMPLETE' });
                                    logService.error(err);
                                    context.setState({
                                        navigation: {
                                            action: 'error',
                                            props: {
                                                retryAction: () =>
                                                    context.props.setInstanceID(
                                                        context
                                                    ),
                                                error: err,
                                            },
                                        },
                                    });
                                });
                        } else {
                            DataDogService.createError(
                                'Could not get session.'
                            );
                            throw new Error();
                        }
                    }
                })
                .catch(err => {
                    DataDogService.createError('Could not get session.');
                    dispatch({ type: 'COMPLETE' });
                    logService.error(err);
                    context.setState({
                        navigation: {
                            action: 'error',
                            props: {
                                retryAction: () =>
                                    context.props.setInstanceID(context),
                                error: err,
                            },
                        },
                    });
                });
        }
    };
}

export function getUUID(recaptchaToken) {
    // Get the uuid
    return dispatch => {
        ApiService.getUuidFromRuuid(
            selectStorage(areCookiesEnabled()).getItem('ruuid'),
            recaptchaToken
        )
            .then(response => {
                selectStorage(areCookiesEnabled()).setItem(
                    'uuid',
                    response.data.uuid
                );
                selectStorage(areCookiesEnabled()).removeItem('ruuid');
                dispatch(
                    {
                        type: 'IS_RUUID',
                        data: false,
                    },
                    dispatch({
                        type: 'IS_RECAPTCHA_VERIFIED',
                        data: true,
                    })
                );
                dispatch(setInstanceID());
            })
            .catch(err => {
                dispatch({ type: 'UUID_FROM_RUUID_ERROR' });
            });
    };
}

export function resetConfig() {
    return {
        type: '@@acuant/RESET_CONFIG',
    };
}

export function submitFrontID() {
    return {
        type: '@@acuant/FRONT_ID_SUBMITTED',
    };
}

export function submitBackID() {
    return {
        type: '@@acuant/BACK_ID_SUBMITTED',
    };
}

export function acuantInitialized(bool) {
    return {
        type: 'ACUANT_INITIALIZED',
        data: bool,
    };
}

export function bypassAcuant() {
    return {
        type: 'BYPASS_ACUANT',
        data: true,
    };
}

export function countError() {
    return dispatch => {
        dispatch({ type: 'COUNT_ERROR' });
    };
}

// Checks if auto capture is supported by device
export function setIsAutoLivenessSupported(autoCaptureSettings) {
    // Get device type
    const device = isIOS() ? 'iphone' : 'android';

    // Checks if an error has already occurred on device
    const isAutoLivenessDisabled =
        areCookiesEnabled() && localStorage.getItem('Auto_liveness_disabled');

    return dispatch => {
        if (
            autoCaptureSettings[device] &&
            (isAutoLivenessDisabled === null ||
                isAutoLivenessDisabled === undefined)
        ) {
            checkCameraAccess()
                .then(access => {
                    areCookiesEnabled() &&
                        localStorage.removeItem('Auto_liveness_disabled');
                    if (access) {
                        dispatch({
                            type: 'AUTO_LIVENESS_SUPPORTED',
                            data: true,
                        });
                    } else {
                        dispatch({
                            type: 'AUTO_LIVENESS_SUPPORTED',
                            data: false,
                        });
                    }
                })
                .catch(error => {
                    logService.error(error);
                    dispatch({
                        type: 'AUTO_LIVENESS_SUPPORTED',
                        data: false,
                    });
                });
        }
    };
}

export function setAutoLivenessError() {
    areCookiesEnabled && localStorage.setItem('Auto_liveness_disabled', 'true');
    return dispatch => {
        dispatch({
            type: 'AUTO_LIVENESS_SUPPORTED',
            data: false,
        });
    };
}

// Checks if auto capture is supported by device
export function setIsIdCaptureSupported(autoCaptureSettings, captureMethod) {
    // Get device type
    const device = isIOS() ? 'iphone' : 'android';

    // Checks if an error has already occurred on device
    const isAutoIDDisabled =
        areCookiesEnabled() && localStorage.getItem('Auto_id_disabled');

    // Checks if an error has already occurred on device
    const isIdpalCaptureOverlayDisabled =
        areCookiesEnabled() && localStorage.getItem('idpal_overlay_disabled');

    // If using IDpal capture
    if (captureMethod === 'idpal') {
        return dispatch => {
            dispatch({
                type: 'IDPAL_OVERLAY_SUPPORTED',
                data: isIdpalCaptureOverlayDisabled !== 'true',
            });
        };
    } else {
        return dispatch => {
            if (
                autoCaptureSettings[device] &&
                (isAutoIDDisabled === null || isAutoIDDisabled === undefined)
            ) {
                checkCameraAccess()
                    .then(access => {
                        areCookiesEnabled() &&
                            localStorage.removeItem('Auto_id_disabled');
                        if (access) {
                            dispatch({
                                type: 'AUTO_ID_CAPTURE_SUPPORTED',
                                data: true,
                            });
                        } else {
                            dispatch({
                                type: 'AUTO_ID_CAPTURE_SUPPORTED',
                                data: false,
                            });
                        }
                    })
                    .catch(error => {
                        logService.error(error);
                        dispatch({
                            type: 'AUTO_ID_CAPTURE_SUPPORTED',
                            data: false,
                        });
                    });
            }
        };
    }
}

export function setOverlayDisabled() {
    areCookiesEnabled() && localStorage.removeItem('idpal_overlay_disabled');
    areCookiesEnabled() &&
        localStorage.setItem('idpal_overlay_disabled', 'true');
    return dispatch => {
        dispatch({
            type: 'IDPAL_OVERLAY_SUPPORTED',
            data: false,
        });
    };
}

export function setAutoIDCaptureError() {
    areCookiesEnabled() && localStorage.setItem('Auto_id_disabled', 'true');
    return dispatch => {
        dispatch({
            type: 'AUTO_ID_CAPTURE_SUPPORTED',
            data: false,
        });
    };
}

export function setInitializationParams(params) {
    if (!params) {
        params = '';
    }
    return {
        type: 'SET_INITIALIZATION_PARAMS',
        data: params,
    };
}

// Sets if an ID has been submitted on init
export function setIsDocSubmitted(isSubmitted) {
    return {
        type: 'IS_DOC_SUBMITTED',
        data: isSubmitted,
    };
}

// Sets if an poa has been submitted on init
export function setIsPoaSubmitted(isSubmitted) {
    return {
        type: 'IS_POA_SUBMITTED',
        data: isSubmitted,
    };
}
