/**
 * @prettier
 */

/* eslint-disable no-unused-vars */
import request from '../shared/request';
import { isMobile, isTablet } from 'react-device-detect';
import { areCookiesEnabled } from '../shared/helpers';
import selectStorage from '../shared/selectStorage';

// eslint-disable-next-line
var pjson = require('../../../package.json');

function getDocInstance() {
    var bodyFormData = new FormData();
    bodyFormData.set(
        'uuid',
        selectStorage(areCookiesEnabled()).getItem('uuid')
    );
    bodyFormData.set('device_type', 'web');

    return request({
        url: '/webapp/session',
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: bodyFormData,
    });
}

/**
 * Gets preload company branding from ruuid
 * @param {string} ruuid  -  reusable universally unique identifiers
 * @returns {Promise} -  returns branding object with company settings
 */
function getBrandingFromRuuid(ruuid) {
    return request({
        url: '/webapp/profile_link/' + ruuid + '/branding',
        method: 'GET',
    });
}

/**
 * Gets the uuid from the ruuid if the recaptcha token is valid
 * @param {string} ruuid -  reusable universally unique identifiers
 * @param {string} recaptchaToken - recaptcha verification token
 * @returns {Promise} - returns the uuid (universally unique identifiers)
 */
function getUuidFromRuuid(ruuid, recaptchaToken) {
    let body = new FormData();
    body.set('recaptchaToken', recaptchaToken);
    return request({
        url: '/webapp/profile_link/' + ruuid + '/uuid',
        method: 'POST',
        data: body,
    });
}

// Document upload calls start

/**
 * Stores paper licence
 * @returns {Promise} - returns the uuid (universally unique identifiers)
 */
function paperLicence(
    instanceID,
    side,
    file,
    cropped,
    documentRegion,
    cancelToken
) {
    let sourceType;
    let bodyFormData = new FormData();

    bodyFormData.set(cropped ? 'image' : 'imageUncropped', file);

    if (isMobile || isTablet) {
        sourceType = 'webapp-capture';
    } else {
        sourceType = 'webapp-upload';
    }
    bodyFormData.set('source_type', sourceType);
    bodyFormData.set(
        'submissionId',
        selectStorage(areCookiesEnabled()).getItem('submissionid')
    );

    return request({
        url: '/webapp/document/store/paperlicence/',
        method: 'POST',
        data: bodyFormData,
        cancelToken: cancelToken,
    });
}

function postImage(
    instanceID,
    side,
    file,
    cropped,
    documentRegion,
    cancelToken
) {
    let sourceType;
    let bodyFormData = new FormData();

    bodyFormData.set(cropped ? 'image' : 'imageUncropped', file);

    if (isMobile || isTablet) {
        sourceType = 'webapp-capture';
    } else {
        sourceType = 'webapp-upload';
    }
    bodyFormData.set('source_type', sourceType);
    bodyFormData.set(
        'submissionId',
        selectStorage(areCookiesEnabled()).getItem('submissionid')
    );

    return request({
        url: '/webapp/document/verification/0/passport/',
        method: 'POST',
        data: bodyFormData,
        cancelToken: cancelToken,
    });
}

// ID-PAL: Passing either cropped and uncropped ID Card images for validation
function processDLDuplex(
    instanceID,
    side,
    file,
    cropped,
    documentRegion,
    cancelToken
) {
    let url;
    let sourceType;
    var bodyFormData = new FormData();
    if (isMobile || isTablet) {
        sourceType = 'webapp-capture';
    } else {
        sourceType = 'webapp-upload';
    }
    bodyFormData.set('source_type', sourceType);
    bodyFormData.set(cropped ? 'image' : 'imageUncropped', file);
    if (side === 0) {
        url = '/webapp/document/verification/0/idcard-front/';
    } else {
        bodyFormData.set('documentRegion', documentRegion);
        url = '/webapp/document/verification/1/idcard-back/';
    }

    bodyFormData.set(
        'submissionId',
        selectStorage(areCookiesEnabled()).getItem('submissionid')
    );

    return request({
        url: url,
        method: 'POST',
        data: bodyFormData,
        cancelToken: cancelToken,
    });
}

/**
 * Stores proof_of_address
 *
 * @param {file} file - pfd of proof of addres document
 * @param {string} poaUploadKey - poa key obtained from the profile
 * @returns {Promise} - returns post response for proof-of-address upload
 */
function uploadPoa(file, poaUploadKey) {
    const bodyFormData = new FormData();

    bodyFormData.set('file', file);
    bodyFormData.set('poaUploadKey', poaUploadKey);
    bodyFormData.set('source_type', 'webapp-upload');
    bodyFormData.set(
        'submissionId',
        selectStorage(areCookiesEnabled()).getItem('submissionid')
    );

    return request({
        url: '/webapp/document/proof-of-address/',
        method: 'POST',
        data: bodyFormData,
    });
}

// Document upload calls end

/**
 * Get document classification to identify the type of document client is uploading.
 *
 * @param image
 * @returns {Promise<unknown>}
 */
function getDocumentClassification(image, cancelToken) {
    // Construct request body
    let body = new FormData();
    body.set('image', image);
    body.set(
        'submissionId',
        selectStorage(areCookiesEnabled()).getItem('submissionid')
    );

    // Make API request and return a Promise
    return request({
        url: '/webapp/document/classification',
        method: 'POST',
        data: body,
        cancelToken: cancelToken,
    });
}

/**
 * Get passive liveness result from the selfie image captured
 *
 * @param image
 * @returns {Promise<unknown>}
 */
function getPassiveLivenessResult(image) {
    // Construct request body
    let body = new FormData();
    body.set('image', image);
    body.set(
        'submissionId',
        selectStorage(areCookiesEnabled()).getItem('submissionid')
    );

    // Make API request and return a Promise
    return request({
        url: '/webapp/facial/passive-liveness',
        method: 'POST',
        data: body,
    });
}

/**
 * Get Submission State
 *
 */

function getSubmissionState() {
    // Construct request body
    let body = new FormData();
    body.set(
        'source_type',
        isMobile || isTablet ? 'webapp-capture' : 'webapp-upload'
    );

    //Make API request and return a Promise
    return request({
        url: '/webapp/state',
        method: 'POST',
        data: body,
    });
}

/**
 * Get Company Branding Colors and logo from the webapp-profile Api service
 *
 */

function getAppProfile() {
    //Make API request and return a Promise
    return request({
        url: '/webapp/profile',
        method: 'GET',
    });
}

/**
 * Finalize the submission.
 *
 * @returns {Promise<unknown>}
 */
function postFinalizeSubmission() {
    // Construct request body
    let body = new FormData();

    body.set(
        'submissionId',
        selectStorage(areCookiesEnabled()).getItem('submissionid')
    );

    // Make API request and return a Promise
    return request({
        url: '/webapp/document/finalize/',
        method: 'POST',
        data: body,
    });
}

/**
 * Submit EVerificitation address for verification
 *
 * @returns {Promise<unknown>}
 * @param data
 */
function postEVerification(data) {
    // Construct request body
    let body = new FormData();
    body.set(
        'submissionId',
        selectStorage(areCookiesEnabled()).getItem('submissionid')
    );
    body.set('firstName', data.firstName);
    body.set('lastName', data.lastName);
    body.set('dob', data.dob);
    body.set('city', data.city);
    body.set('country', data.country);
    body.set('address1', data.address1);
    body.set('address2', data.address2);
    body.set('postalCode', data.postCode);
    body.set('phone', data.phone);
    body.set('phonePrefix', data.phonePrefix);
    body.set('phoneRaw', data.phoneRaw);
    body.set('eVerificationCustomField', data.customField);
    body.set('uuid', selectStorage(areCookiesEnabled()).getItem('uuid'));

    // Set source type
    body.set(
        'source_type',
        isMobile || isTablet ? 'webapp-capture' : 'webapp-upload'
    );

    // Make API request and return a Promise
    return request({
        url: '/webapp/address/verification',
        method: 'POST',
        data: body,
    });
}

/**
 * Submit address for storage
 *
 * @returns {Promise<unknown>}
 * @param data
 */

function postStoreAddress(data) {
    // Construct request body
    let body = new FormData();
    body.set(
        'submissionId',
        selectStorage(areCookiesEnabled()).getItem('submissionid')
    );
    body.set('firstName', data.firstName);
    body.set('lastName', data.lastName);
    body.set('dob', data.dob);
    body.set('city', data.city);
    body.set('country', data.country);
    body.set('address1', data.address1);
    body.set('address2', data.address2);
    body.set('postalCode', data.postCode);
    body.set('phone', data.phone);
    body.set('phonePrefix', data.phonePrefix);
    body.set('phoneRaw', data.phoneRaw);
    body.set('eVerificationCustomField', data.customField);
    body.set('uuid', selectStorage(areCookiesEnabled()).getItem('uuid'));

    // Set source type
    body.set(
        'source_type',
        isMobile || isTablet ? 'webapp-capture' : 'webapp-upload'
    );

    // Make API request and return a Promise
    return request({
        url: '/webapp/address/store',
        method: 'POST',
        data: body,
    });
}

/**
 * Get Countries for populating dropdowns
 *
 */

function getCountries() {
    //Make API request and return a Promise
    return request({
        url: '/webapp/countries',
        method: 'GET',
    });
}

/**
 * Perform Address Lookup
 *
 */

function lookupAddress(address, countryCode) {
    // Construct request body
    let body = new FormData();
    body.set('iso_country', countryCode);
    body.set('free_form_address', address);

    //Make API request and return a Promise
    return request({
        url: 'webapp/address/lookup',
        method: 'POST',
        data: body,
    });
}

/**
 * Send SMS for Mobile Handoff
 */

function postSendHandoffSms(data) {
    // Construct request body
    let body = new FormData();
    body.set('phone', data.phone);
    body.set('uuid', data.uuid);
    body.set('lang', data.lang);
    body.set(
        'submissionId',
        selectStorage(areCookiesEnabled()).getItem('submissionid')
    );

    //Make API request and return a Promise
    return request({
        url: '/webapp/handoff-sms',
        method: 'POST',
        data: body,
    });
}

/**
 * Get Token for acuant sdk
 */

function getToken() {
    //Make API request and return a Promise
    return request({
        url: '/acuant/token',
        method: 'GET',
    });
}

/**
 * Get credentials for datadog sdk
 */

function getDataDogCredentials() {
    //Make API request and return a Promise
    return request({
        url: '/webapp/datadog',
        method: 'GET',
    });
}

const ApiService = {
    getDocInstance,
    postImage,
    processDLDuplex,
    paperLicence,
    getDocumentClassification,
    getPassiveLivenessResult,
    getAppProfile,
    getCountries,
    postSendHandoffSms,
    postEVerification,
    postStoreAddress,
    postFinalizeSubmission,
    getSubmissionState,
    lookupAddress,
    getToken,
    getDataDogCredentials,
    getBrandingFromRuuid,
    getUuidFromRuuid,
    uploadPoa,
};

export default ApiService;
